import {TVContainer} from '../../styles/styled-components'
import {Link } from 'react-router-dom'
import {useEffect, useState} from 'react'
import {Radio} from 'semantic-ui-react'

const TV = (props) => {
  
  

  return (
    <TVContainer>
      <div className="tv-inner-wrap">
        <div className="tv-screen">
          {props.children}
  
        </div>
        <div className="tv-controls">
          <div className="tv-dial">
            <div className="triangle tri-up" onClick={() => props.changeChannel(true)}></div>
            <div className="tv-feature">{props.selectedProject.name}</div>
            <div className="triangle tri-down"onClick={() => props.changeChannel(false)}></div>
          </div>
          <div className="tv-dial">
          <div className="triangle tri-up"></div>
            <div className="tv-feature"><Link to="/">UNDER CONSTRUCTION RETURN HOME</Link></div>
            <div className="triangle tri-down"></div>
            
          </div>
          <div className="mute-wrap"><div className="muted" onClick={props.setIsMuted}>{props.isMuted ? "Play Audio" : "Mute"}</div>
            <Radio toggle onChange={props.setIsMuted} />
          </div>
        </div>
      </div>
    </TVContainer>
  )
}

export default TV;