import React from 'react'
import {Link} from 'react-router-dom'
import './index.css'

class SubmittedMessage extends React.Component {
  constructor(props) {
       super()
   }


  render() {
    return (
      <div className="submitted-message">
          <div className="message-line"></div>
          <div className="message-line effect-line-2"></div>
          <div className="message-line effect-line-3"></div>
          <div className="message-line effect-line-4"></div>
          <div className="message-line effect-line-5"></div>
        <div>{this.props.newMessage}</div>
      </div>
    )
  }
}

export default SubmittedMessage
