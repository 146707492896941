  
import React from 'react'
import {Link} from 'react-router-dom'
import ScanningHit from '../ScanningHit'

class ScanningHitsContainer extends React.Component {
    state = {
        allHits: this.props.allHits || [],
    }

    componentDidMount() {
        console.log("SCNNING CONT", this.props.allHits)
    }

    render () {
        const scanningHitLi = this.props.allHits.map((record, index) =>
            <li key={index}><ScanningHit hit={record} isActive={this.props.isActive} index={index} /></li>
        );

        return (
              <ul className="scanning-hit-wrapper">
                  {scanningHitLi}
              </ul>
        )
    }
}


export default ScanningHitsContainer