import React from 'react';
import ff from '../../assets/ff.png'

export default class SongSelector extends React.Component {
  render() {
    return (
      // <label>
      //   Select a song:{' '}
      //   <select value={this.props.songs.indexOf(this.props.selectedSong)} onChange={this.handleSongChange.bind(this)}>
      //     <option />
      //     {this.renderSongOptions()}
      //   </select>
      // </label>

      <div className="random-song control-button" onClick={this.handleSongChange.bind(this)}><img src={ff}/></div>
    );
  }

  // renderSongOptions() {
  //   // console.log(randomSongIndex)
  //   return this.props.songs.map((song, index) => {
  //     return (
  //       <div key={index} value={index}> {song.title}</div>
  //     );
  //   });
  // }

  handleSongChange(ev) {
    const randomSongIndex = Math.floor(Math.random() * this.props.songs.length);
    this.props.onSongSelected(this.props.songs[randomSongIndex]);
  }
}
