

import {FWFutureContainer} from '../styled.js'
import {Link} from 'react-router-dom'
import {formatDate} from '../../../utils/helpers.js'


const FWHeader = () => {
  return (
    <header>
    <Link class="fw-mast-link" to="/fwfutures"><h1 className="nl-header">The Future Times</h1></Link>
    <div className="header-2-container">
    <ul className="nl-header-2">
      <li>Vol. 1  Issue 0</li>
      <li>{formatDate()}</li>
      <li>
        <div className="newsletter-social-icons">
          <a className="social-icon-letters" href="https://www.linkedin.com/company/the-1994?trk=vsrp_companies_res_name&trkInfo=VSRPsearchId%3A975829581443042003381%2CVSRPtargetId%3A10096174%2CVSRPcmpt%3Aprimary">a</a>
          <a href="https://twitter.com/1994israd" className="social-icon-letters">c</a>
          <span onClick="">donate</span>
        </div>
      </li>
    </ul>
    </div>
  </header>
  )

}

export default FWHeader 