
import styled from 'styled-components'
import React, {useState} from 'react'

const StyledGallery = styled.div`

  display: flex;
  justify-content: center;
  height: 100vh;

  h1 {
    position:absolute;
    bottom: 0;
    right: 0;
  }

  .art-container {
    min-height: 600px;
    max-width: 800px;
    position: relative;
  }
  .art-outer {
    max-width: 900px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    display: flex;
  
  }

  iframe {
    width: 100%;
    // height: auto;
    border: none;
    position:absolute; 
    top:0; 
    left: 0
  }

  .art-info-toggle {
    position: absolute;
    top: 60px;
    right: 60px;
    font-size: 18px;
    font-family: 'Times New Roman';
    cursor: pointer;
    border: 1px solid grey;
    height: 32px;
    width: 32px;
    z-index: 10000;

    p {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .tombstone {
    border: 1px solid #333;
    padding: .35em 1.4em;;
    display: inline-block;
    bottom: 0;
    position: absolute;
  }

  @media only screen and (max-width:880px) {
    .art-container {
      width: 100%;
      max-width: auto;
    }

  }
`

const Gallery = () => {
  const [showTombStone, setShowTombStone] = useState(false)


  return (
    <StyledGallery>
     <h1> Gallery </h1>
    {/* <div onClick={() => setShowTombStone(!showTombStone)}className="art-info-toggle"><p>I</p></div> */}
     <div className="art-outer">
     
    <div className="art-container">
      <iframe height="100%" width="100%" src="https://joystick.netlify.app/" title="Joystick"></iframe>
      
      {showTombStone && 
      <div className="tombstone">
        {/* <div>Joystick</div> */}
        <div>generative p5.js</div>
      </div> }
    </div>

    </div>
    </StyledGallery>
  )
}

export default Gallery