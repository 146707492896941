import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import Sound from 'react-sound';
import bgVid1 from '../../assets/hflr-bg-vid-2.mp4'
import '../../styles/home.css'
import '../../styles/glitch.css'
import interlude from '../../assets/Interlude.mp3'
import PlayerControls from '../../components/PlayerControls';
import SongSelector from '../../components/SongSelector';
import songs from '../../assets/songs';
import TOC from '../../components/TOC'
import HomePanelLink from './components/HomePanelLink.js'
import SocialBox from '../../components/SocialBox'
import Signup from '../../components/Signup'
import Messages from '../../components/Messages'
import HiddenForm from '../../components/HiddenForm'
import SubmittedMessage from '../../components/SubmittedMessage'
import {HomeContainer, Blur, HomeSectionLinks, HeaderSection, HeaderContainer} from '../../styles/styled-components.js'
import CovidBlock from './components/covid.js'
import HomeLinks from './components/links.js'
import axios from 'axios'
import GlitchClip from 'react-glitch-effect/core/GlitchClip';
import GlitchText from 'react-glitch-effect/core/GlitchText';


// import Typing from 'react-typing-animation';


const Home = () => {
  const [covidDaily, setCovidDaily] = useState(null);
  
//OLD STATE
        //  soundMenu: false,
        //  controlled: true,
        //  currentSong: songs[0],
        //  position: 0,
        //  volume: 100,
        //  playbackRate: 1,
        //  loop: false,
        //  playStatus: Sound.status.STOPPED,
        //  social: false,
        //  signup: false,
        //  currentMessage:'',
        //  hideMessages: false,
        //  showNewMessage: false,
        //  covidDaily: null
       
      //  this.checkForMessages = this.checkForMessages.bind(this);
      //   this.handleNewMessage = this.handleNewMessage.bind(this);


   useEffect(() => {
     const covidData = getCovidStats()
     console.log('CD', covidData)
    if (covidData) {
      setCovidDaily({covidDaily: covidData})
    }
   }, [])

   
 
  const getCovidStats = () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }
     return axios.get('https://covidtracking.com/api/us/daily', requestOptions)
     .then(response => response)
    .catch(error => console.log('error', error));
   }

   const checkForMessages = () => {
      //  console.log('polling');
      //  fetch("https://api.airtable.com/v0/appCZCWlpEMmolAwH/Table%201?maxRecords=5&view=Grid%20view", {
      //   headers: {"Authorization": "Bearer keyN4bQqzj0O0fVbT"}
      // }).then((response) => response.json())
      //     .then((responseData) => {
      //       const messages = responseData.records
      //       let messageArray = []
      //       let eventArray = []
      //       // const currentMessage = messages[0]
      //       messages.map((message) => {
      //           // const currentMessage = messages[0]
      //           if (message.fields.type === 'message') {
      //               messageArray.push(message);
      //               this.setState({
      //                   messages: messageArray
      //                   // currentMessage: this.state.messages[0]
      //               },() => { console.log(this.state.messages)})
      //           }
      //           if (message.fields.type === 'event') {
      //               eventArray.push(message)
      //               this.setState({
      //                   events: eventArray
      //               },() => { console.log(this.state.events)})
      //           }
      //       })
      //     })
      //   .catch(function(err) {
      //     console.log(err);
      //   });
    }

   const getStatusText = () => {
    switch (this.state.playStatus) {
      case Sound.status.PLAYING:
        return 'playing';
      case Sound.status.PAUSED:
        return 'paused';
      case Sound.status.STOPPED:
        return 'stopped';
      default:
        return '(unknown)';
    }
  }


   const handleSubmission  = (newMessage) => {
  //    console.log(newMessage)
  //    this.setState({
  //           signup:false,
  //           hideMessages: false,
  //           newMessage: newMessage,
  //           showNewMessage:true
  //    }, () => {
  //        setTimeout(this.handleNewMessage,3000)
  //    })
  //  }

  //  handleNewMessage() {
  //      console.log('turn message off');
  //      this.setState({showNewMessage: false});
   }

  // render() {
    // const { volume, playbackRate, loop } = this.state;
    const currentMessage = "";
    const messages = [];
    const events = [];
    const newMessage =  [];

    console.log('COVID STATS', covidDaily)
    return (
      <HomeContainer>
        <div className="home-overlay-2">
        <div className="home-panel hp1">
          <HomePanelLink text="I have a solid mixture of futures, foresight, software development, strategy, and product skills. Do work?" link="/work"/>
           <h2>Work</h2>s
           {/* <div className="hp-panel-inner">
              <div className="hp-i-w">
                <div className="hp-slide-wrapper hp-slide">
                  <h3>Future of Death</h3>
                </div>
              </div>
              <div className="hp-slide-wrapper hp-slide">
               <h3> Code the Dream</h3>
              </div>
              <div className="hp-slide-wrapper hp-slide">


                </div>
              <div className="hp-slide-wrapper hp-slide">
                <h3>Coming Soon -Importance of Blockchain</h3>
              </div>
           </div> */}
            </div>
            <div className="home-panel hp2">
              <HomePanelLink text="The Future Times. A site where I write about, explore, and curate important trends and potential futures." link="/fwfutures"/>
              <h2> Writing</h2>
            </div>
            <div className="home-panel hp3">
            <HomePanelLink text="Coming Soon..." link="/gallery"/>
            <h2>Music and Art</h2> 
            </div>

        </div>
        <HeaderContainer>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <HeaderSection>
          {/* {covidDaily && <CovidBlock covidDaily={covidDaily}/> } */}
        </HeaderSection> 
        <HeaderSection>
          {/* Violence */}
        </HeaderSection> 
        <HeaderSection>
          {/* Climate */}
          </HeaderSection> 
        <HeaderSection>
          {/* Wealth Inequality */}
          </HeaderSection> 
          <div className="glitch-clip">
            <div className="header-outer">
            {/* <GlitchText
              color1={"#ddd"}  
            > */}
              <h1 className="header-text header-2">
                TOM<span className="header-2">RAU</span>
              </h1>
             
            {/* </GlitchText> */}
            {/* <GlitchText
              color1={"#ddd"}  
            > */}
            {/* <h3>COMING SOON</h3> */}
            {/* </GlitchText> */}
            </div>
          </div>
        </HeaderContainer>
        <div className="home-page">
         {/* { this.state.showNewMessage ? <SubmittedMessage
             newMessage={newMessage}
         /> : null }

         { events  && (this.state.hideMessages !== true) ?
            // <Messages
            //     currentMessage={currentMessage}
            //     messages = { messages }
            //     events = { events }
            // />
         <div>"Let's create a better story"</div>
        : null } */}
        <TOC
          // menuState={this.state.menuState}
          // onListenClick={this.toggleSoundMenu.bind(this)}
          // onFollowClick={this.renderSocial.bind(this)}
          // signupClick={this.renderSignup.bind(this)}
        />
        {/* {this.state.social ?
          <SocialBox
          /> : null}
          {this.state.signup ?
            <Signup
                afterSubmit={this.handleSubmission.bind(this)}
            /> : null} */}
        <div className="hero-container">
            <div className="home-overlay">
     
            </div>
            <video playsInline autoPlay muted loop poster="" id="bgvid">
              <source src={bgVid1} type="video/mp4" />
            </video>
            {/* <div className="song-position glitch-text">{(this.state.position/1000).toFixed(4)}</div>
            <div className="song-position glitch-text">{(this.state.position/1000).toFixed(4)}</div>
            <div className="song-position glitch-text">{(this.state.position/1000).toFixed(4)}</div> */}

          
        
          </div>
          {/* <HiddenForm /> */}
        </div>
        <div className="home-links-container">
          <div className="home-about">
            <h2>Bio</h2>
            <p>From playing music professionally to being a successful and top ranked professional poker player, Tom’s interests, curiosities, and obsessions have always cast a wide net. For the past 15 years however he has been focused on technology, first in the user interface and design field and then on into product development, design, and management. The deeper into the tech space he got however the more he was convinced that we are building too much technology with a carefree attitude about it’s implications on society and the future. Ultimately, this led him to the University of Houston graduate program where he finished his Master’s of Science in Foresight Studies in the spring of 2021. </p>
              
            <p>Tom is currently working as a senior staff mentor, lead developer, and curriculum designer at Code the Dream, a non-profit coding school and apprenticeship program with a mission to provide jobs in technology to underrepresented groups. He is looking for opportunities to extend his research in how we can better use technology to serve the needs of society in the future. He is currently reading and writing a lot about blockchain, the future of life and death, cities, and how we as humans can better use technology inclusively to transform some of societies broken institutions.</p>
          </div>
          
        </div>
      </HomeContainer>
    )

}



export default Home
