import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import {FWFutureContainer, FWPostContainer} from './styled.js'
import React, {useState, useEffect, useContext, useRef} from 'react'
import ReactMarkdown from 'react-markdown'
import P5Component from '../../components/P5/P5Component'
import rehypeRaw from 'rehype-raw'
import "./futures.css"
import { store } from '../../store.js';
import Web3 from 'web3';
import 'semantic-ui-css/semantic.min.css';
import {formatDate} from '../../utils/helpers.js'

const FWFutures = () => {
  const [posts, setPosts] = useState([])
  const [scans, setScans] = useState([])
  const globalState = useContext(store);
  const {dispatch} = globalState
  const [featurePost, setFeaturedPost] = useState(
  {
    content: {rendered: "Loading"},
    slug: ''
  }
  )
  const [postTwo, setPostTwo] = useState(
    {
      content: {rendered: "Loading"},
      title: {rendered: "Loading..."},
      excerpt: {rendered: "loading..."},
      slug: ''
    }
    )
  
  
  useEffect(() => {
    getScans()
    getPosts()
    getCategories();
  },[])

  const getCategories = () => {
    fetch('https://raucms.wpengine.com/wp-json/wp/v2/categories').then((response) => {
      console.log('CG RESPONSE', response)
      // const featuredBlogs = response
      // setPosts(featuredBlogs)
     return response.json()
    })
    .then((responseData) => {
        // const allHits = responseData.records.filter(record => record.fields.number)
        const blogCategories = responseData
        dispatch({type: 'SET_CATEGORIES', payload: blogCategories})
    })
  }

  const getPosts = () => {
    fetch('https://raucms.wpengine.com/wp-json/wp/v2/posts').then((response) => {
      console.log('RESPONSE', response)
      // const featuredBlogs = response
      // setPosts(featuredBlogs)
     return response.json()
    })
    .then((responseData) => {
            // const allHits = responseData.records.filter(record => record.fields.number)
            const featuredBlogs = responseData
            setPosts(featuredBlogs)
            dispatch({type: 'SET_POSTS', payload: featuredBlogs})
    })
  }

  https://poststatus.com/wp-json/wp/v2/posts/?_embed&per_page=3&author=1

  useEffect(() => {
    const featured = posts.filter(post => post.featured_future === "true")
    const postTwo = posts.filter(post => post.featured_future === "false")
    setFeaturedPost(featured[0])
    setPostTwo(postTwo[0])
 }, [posts]);



console.log('FD', formatDate())

// console.log('HEIGHT', height)
  const getScans = () => {
    fetch("https://api.airtable.com/v0/appf47EqIOrHTrGoP/scans?maxRecords=6&view=Grid%20view", {
      headers: {"Authorization": "Bearer keysWOUXtGyYz8SY2"}
    }).then((response) => response.json())
        .then((responseData) => {
            // const allHits = responseData.records.filter(record => record.fields.number)
            const allHits = responseData.records;
            setScans(allHits)
      })
 }

  let featuredMarkdown = featurePost ? featurePost.content.rendered.substring(0,2090) : ""
  featuredMarkdown = featurePost ? featuredMarkdown.substr(0, Math.min(featuredMarkdown.length, featuredMarkdown.lastIndexOf(" "))) + `...<div className="fw-read-more"><a href="/fwfutures/${featurePost.slug}">read more</a></div>` : "loading..."
  const ethereum = window.ethereum;
   
  const donateCrypto = async () => {
    const myEth = '0x9fc181b94d47dE12219D4d9e8D71F0fcd3e13f2B';


    if (window.ethereum) {
      await window.ethereum.send('eth_requestAccounts');
      window.web3 = new Web3(window.ethereum);
      return true;
    }
    return false;
  
  }
  //   if (typeof web3 === 'undefined') 
  //   {
  //   // return renderMessage('<div>You need to install <a href=“https://metmask.io“>MetaMask </a> to use this feature.  <a href=“https://metmask.io“>https://metamask.io</a></div>')
  //   }

  //  let user_address = web3.eth.accounts
  //  console.log('yo', user_address)

  // web3.eth.sendTransaction(
  //   {
  //     to: MY_ADDRESS,
  //     from: user_address,
  //     value: web3.toWei('0.01', 'ether'),
  //   }, 

    // function (err, transactionHash) 
    //     {
    //     if (err) return renderMessage('There was a problem!: ' + err.message)
    //     renderMessage('Thank you from one of the futures. Cubs and Bulls Win!!!')
    // })
  console.log('RENDER')

  return (
    <FWPostContainer>
    <header>
    <h1 className="nl-header">The Future Times</h1>
    <div className="header-2-container">
    {/* <hr className="top-line" /> */}
    <ul className="nl-header-2">
      <li>Vol. 1  Issue 0</li>
      <li>{formatDate()}</li>
      <li>
        <div className="newsletter-social-icons">
          {/* <a className="social-icon-letters" href="https://www.linkedin.com/company/the-1994?trk=vsrp_companies_res_name&trkInfo=VSRPsearchId%3A975829581443042003381%2CVSRPtargetId%3A10096174%2CVSRPcmpt%3Aprimary">a</a>
          <a href="https://twitter.com/1994israd" className="social-icon-letters">c</a> */}
          <span onClick={donateCrypto}>donate</span>
        </div>
      </li>
    </ul>
    </div>
  </header>
     <FWFutureContainer>
       

        <section className="flex-container left-col">
          <div className="column-one">
         
           {/* POST TWO */}
          {postTwo && 
            <Link to={`/fwfutures/${postTwo.slug}`} className="nl-list-container post-two">
              <Link className="" to={`/fwfutures/${postTwo.slug}`} ><h3>{postTwo.title.rendered}</h3></Link>
              <div>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{`${postTwo.excerpt.rendered}`}</ReactMarkdown>
              <Link to={`/fwfutures/${postTwo.slug}`}>read more</Link>
              </div>
            </Link> }
        
 
        
                {/* <li><a className="open-link" target="_blank" href="http://www.empireonline.com/features/empire-introducing-the-internet">The Internet is Coming: What You Need to Know</a><a className="open-link" href="http://www.empireonline.com/features/empire-introducing-the-internet" target="_blank">p2</a></li>
                <li><a className="open-link" target="_blank" href="mixtape/index.html">Mixtape Alpha: Music from Durham, NC</a><a className="open-link" target="_blank" href="mixtape/index.html">p2</a></li>
              <li><a className="toc-link" href="">Amazing Shit That Happened in 1994</a><a className="toc-link" href="">p2</a></li>
              <li><a className="toc-link" href="">Op-Ed: Do we even exist?</a><a className="toc-link" href="">p2</a></li>
              <li><a className="toc-link" href="">Missed Connections: Craigslist's finest, curated</a><a className="toc-link" href="">p2</a></li>
              <li><a className="toc-link" href="">Local Calendar</a><a className="toc-link" href="">p2</a></li>
              <li><a className="" href="../index.html">Our Company</a><a className="" href="../index.html">p2</a></li> */}
         
          <div className="nl-list-container">
            <Link to="/scannr"><h3>Recent Scans & Signals</h3></Link>
            <ul className="nl-list">
              {scans.map(scan => <li key={scan.fields.Name}>
                <div className="nl-scan">
                  <a target="_blank" href={scan.fields.url}>{scan.fields.Name}</a>
                 </div>
                {scan.fields.tags_short ? scan.fields.tags_short.map((tag, index) => {
                  return (
                    <div key={index} className="fw-tags">{tag}</div>
                  )
                })
               : null } 
              </li>)}
            </ul>
          </div>
          
      
          <div className="p5-outer" >
              <h3 className="art-header">Coming Soon - Has Art Gone Mad?</h3>
              <P5Component width={337} height={400}/>
            </div>


          <div className="signup-column1">

              {/* <p></p> */}
            </div>
            
            <div className="signup-column2">
              <form action="http://the1994.us11.list-manage.com/subscribe/post" method="POST">
                <h2 className="nl-hl-2">SUBSCRIBE TODAY!</h2>
                <input type="hidden" name="u" value="340aad97819fe7c9d19754ba2" />
                <input type="hidden" name="id" value="94488a57cb" /> 
              {/* <!-- <h1>Sign Up</h1> --> */}
              {/* <!-- <label>Name:</label> --> */}
                <input type="text" id="name" name="MERGE1" placeholder="name" required /> <br />
              {/* <!-- <label>Email:</label> --> */}
                <input type="email" id="mail" name="MERGE0" placeholder="email" required /> <br />
                <button type="submit"> Sign Up </button>
              </form>
            </div>

        


        </div>	
    </section>
    
    <section className="main-col">
		<div className="column-two">
			<h1 className="newspaper-heading">The Internet is going decentralized. It might just rule your face!</h1>
			{/* <h2 className="newspaper-subheader">Are you and your family ready?</h2> */}
			<div className="newspaper-pic-container">
				<img src="https://s3-us-west-2.amazonaws.com/1994/beavis.gif" className="newspaper-pic" alt="Fantastic Four" />
				<div className="newspaper-caption">Beavis and Butthead just got Becky's dad's Netflix password.</div>
			</div>
        {featurePost && 
          <Link to={`/fwfutures/${featurePost.slug}`} className="feature-wrapper">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{`${featuredMarkdown} `}</ReactMarkdown>
          </Link> }
{/* <!--			<p>The internet might be the human race's most amazing invention. And its strangeness, weirdness, and amazingness is all due to the fact that it really is a mirror of our collective unconscious. The internet is truly all connected—through the power of links—hosting all the beautiful and interesting and dark and pointless ideas in the world. And sometimes we need to protect and celebrate that.</p>--> */}
<div>
              <h2 className="nl-hl-3">About the Times</h2>
              <p>The best thing about writing about the future is that almost anything might and could happen. There is an endless amount to be curious of and explore. In my short time left on earth I don't plan on leaving much out. But short story, I have a Masters Degree in Foresight Science (futurism) from University of Houston, am a software developer (if you couldn't tell), a musician, and someone who cares deeply about making the world better and more fair for everyone that lives in it. <Link to="/">Learn more here</Link></p>
            </div>
		</div>

	</section>	

	{/* <section className="flex-container">
		<div className="column-one">
	
		
		</div>
	</section> */}



	<footer className="newsletter-footer">
		<Link to="/" className="homepage-link">Home</Link>
		<p className="issue-number"> ISS. 0 </p>
		<div className="newsletter-social-icons">
			<a className="social-icon-letters" href="https://www.linkedin.com/company/the-1994?trk=vsrp_companies_res_name&trkInfo=VSRPsearchId%3A975829581443042003381%2CVSRPtargetId%3A10096174%2CVSRPcmpt%3Aprimary">a</a>
			<a href="https://twitter.com/1994israd" className="social-icon-letters">c</a>
		</div>
		<p className="page-number">P.1</p>
	  </footer>
  
  
    </FWFutureContainer>
    </FWPostContainer>
  )
}

export default FWFutures