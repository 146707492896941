import {EssayWrapper, EssaySection, EssayHeader, EssaySectionContent} from '../../styles/styled-components.js'
import Byline from './byline.js'
import {Link} from 'react-router-dom'


const Positivism = () => {
  return ( 
    <EssayWrapper>
      <div className="essay-left">
        <Link className="left-content" to="/essays">Back</Link>
      </div>
      <div className="essay-content positivism">
        <EssayHeader>
          <EssaySectionContent>
          <h1>The Case for Positivism in a Post Truth Society.</h1>
          <Byline />
          </EssaySectionContent>
          </EssayHeader>
        <EssaySection>
          <EssaySectionContent>
          <p>
          Positivism is a theory originally applied to social sciences by Comte in the early to mid-19th century (Noble, 2002).  Comte, the father of Positivism, defined it as the neverending search for invariant laws of the natural and social world. He identified three basic methods for discovering these laws - observation, experimentation, and comparison (Maheshwari 2013). In short, the scientific method can not only provide a groundwork to find empirical truths in the natural world around us - science -  but also to find these same truths in human interactions - society.
          </p>
          <p>
          As a species who passes down knowledge human beings, as they hurdle through evolution and time, will continuously replace the metaphysical and unknown world with ideas rooted in empirical truths. Comte was the first to argue that these universal truths also applied to the social sciences and human interaction. Human development, according to Comte, would in fact coincide very much with the Greek word for truth, Aletheia, which is defined literally as, “the uncovering of the essence of a thing.” It is implying much like science that truth, and for that matter positivism, is an incomplete art. The thing we are searching for, truth, will always ultimately be at least partially covered and certainly always in a state of uncovering. 
          </p>
          <p>
            Today we live in a fractured society that is being very much dominated by tribalism. Truth and science is under constant attack - Climate Change, election fraud, crowd size - the lack of respect and the ability to manipulate data is ubiquitous. Add to that the fact that through social media and the personalized algorithms of the internet each of us can seemingly curate our own reality. It makes it all too easy to discount positivism. I will argue however, that not only are we still living in a world in which positivism as a theory is valid and more important than ever, but that the fractured state of our society today is all part of an ongoing journey towards reason and truth that still fit within the original intent of Comte’s positivism. 
          </p>
          <p>
          First, I will argue that post-positivists are in fact positivists and that their primary critique actually fits within the confines of Comte’s original intent. Second, we must look at the journey of truth, fact, and knowledge in historical context. This will provide the context to see the data revolution in its current iteration as a driver of the tribalism we are currently mired in and how that a return towards the underlying ideas of positivism will help us restore faith in knowledge. 
          </p>
          </EssaySectionContent>
        </EssaySection>
        <EssaySection>
        <EssaySectionContent>
          <h2>Reconciling Positivism and Post-positivism</h2>
          <p>
          Critics of Positivism discount Positivism by oversimplifying one of its main points. German theoretical physicist, Werner Heisenberg said this of Positivism (Jaeger 2003): 
          </p>
          <blockquote>
          The positivists have a simple solution: the world must be divided into that which we can say clearly and the rest, which we had better pass over in silence. But can any one conceive of a more pointless philosophy, seeing that what we can say clearly amounts to next to nothing? If we omitted all that is unclear we would probably be left with completely uninteresting and trivial tautologies
          </blockquote>
          <p>
          It is important to keep in mind that Heisenberg was a theoretical physicist living in the mid 20th century nearly a hundred years after Comte, and was a pioneer of quantum physics, a branch of science that begins to question our understanding of not only physics but some of the building blocks of science itself. Heisenberg’s uncertainty theory states in layman's terms that you cannot measure something without affecting it. It lays the groundwork for a pretty huge idea - if we are part of every experiment and also affect the outcome of every experiment, then how do we know that we are attempting to explain behaves exactly the same when we do not observe it? It very much points out an underlying flaw in the scientific method, that our necessity to observe affects our ability to compare. As observers we have an incomplete knowledge of truth. Much like with alitheia, science and truth itself is an act of discovery. It doesn’t mean positivism is wrong, it means that knowing truth is an ongoing act. Our understanding of Science has become more fluid than it was during the times of Comte and Heisenberg himself was one of the first scientists to prove this. 
  
          </p>
          <p>
          It is entirely plausible to think that Comte’s own views would have aligned with post-positivism if he had been privy to 20th and 21st century knowledge. He might not argue that the introduction of dark matter has no meaning, only that it means that our current understanding of it is incomplete. Quantum physics, the role of the human observer in science are both themselves scientific discoveries exemplified by ‘aletheia,’ and both help elucidate the nature of observable truth itself. I don’t think it’s a stretch to think that Comte, who allowed for social evolution would not also allow for scientific evolution. Post-positivism isn’t really anti-positivism it is just positivism later. And the answer to the question as to why on the surface it seems like positivism, and for that matter truth itself, could be labeled dead is provided by looking at the period of time between the Reformation and the Enlightenment. That transitional period in the mid seventeenth century shares many similarities with where humans are today; transitioning between the industrial age and a true digital age. They both marked huge shifts in how information was disseminated. 
          </p>
        </EssaySectionContent>
        </EssaySection>
        <EssaySection>
         <EssaySectionContent>
            <h2>The Truth in Historical Context</h2>
            <p>
            This is not the first time humans have been losing a battle with reason. The role of the printing press during the Reformation is a perfect example. At the time, the Catholic Church was believed to be the one and only source of divine truth in European society but with the invention of the printing press all of this changed. Luther was able to create his 95 Theses, reproduce them at a low cost, and quickly disseminate accounts of the many abuses of the Catholic Church (Levine 2020). However, Luther was not the only one spreading new ideas on the printed page. The printing press leveled the field for other bad actors who had their own truths and mistruths to share. People who practiced witchcraft, demonology, alchemy and the like were able to promote their own agenda: soliciting testimonials, speaking to people in new languages. The printing press was the Twitter of the 16th century. Truth was challenged, misinformation was rampant, and society as a whole was ill-equipped to handle it. Enter the enlightenment and the advent of modern education.  
            </p>
           <p> During the Enlightenment theories of education shifted away from teaching us the ‘will of God ‘ and towards the passing on of observable knowledge (Gilead 2005). These separation of religion from  education was perpetuated by the great thinkers of the time, like British Empiricist John Locke who in the late 1600s wrote two widely translated and distributed pieces on education, Some Thoughts Concerning Education, and An Essay Concerning Human Understanding which put forth the ideas that the human brain is a blank slate (tabula rasa) and that education is best served to teach us to be productive members of society based on observable truths and not the whims of God.  In the latter Lock states, “it is evident, that all children and idiots have not the least apprehension or thought of them. And the want of that is enough to destroy that universal assent which must needs be the necessary concomitant of all innate truths: it seeming to me near a contradiction to say, that there are truths imprinted on the soul, which it perceives or understands not: imprinting, if it signify anything, being nothing else but the making certain truths to be perceived. For to imprint anything on the mind without the mind’s perceiving it, seems to me hardly intelligible” (Locke, 1894). Education, much like truth itself, requires discipline of the mind and structure lest we fall back on metaphysical ideas that easily fit our worldview. </p>
  
            <p>
              Locke’s ideas helped birth modern education, and the breakthroughs in technology like the printing press allowed for the mass distribution of a more structured and rigid teaching curriculum based on fact and reason. The printing press, the same tool used to promote fake facts allowed educators to print thousands of copies of new educational materials that promoted truth. The same technology that was once the source of some of our earliest forms of distributed propaganda, was ultimately used by teachers around the world to pass down science and knowledge to younger generations. 
            </p>
  
            <p>
              Technological developments are not inherently good or bad; they can only be judged by how we use them. Every scientific and technological breakthrough uncovers potential new truths that may disprove and rock our entire worldviews.  There will always be parts of society that reject these new truths or adopt them at a slower rate but actual progress is the eventual move towards the adoption of truth. The fact remains the same -- new ways to disseminate knowledge provide new ways to subvert truth. The internet has provided a similar opportunity of subversion of truth as we shift into a more digital age. 
            </p>
         </EssaySectionContent>
        </EssaySection>
        <EssaySection>
          <EssaySectionContent>
            <h2>The Data Revolution </h2>
            <blockquote>
              For a while, journalists functioned as gatekeepers – they manufactured and controlled the distribution of the news. “With the internet, that has begun to break down.
              -Paul Rutherford (in Levine, n.d.), professor emeritus of history in the Faculty of Arts & Science. 
            </blockquote>
            <p>There is no question that the internet is one of the most disruptive technologies humans have ever created. It has both managed to connect us all together and break us all apart in deeply profound ways that we are now only realizing.  The beauty of the internet, that is a decentralized network of computers that anyone can access, connect to, and post on, is also its curse. There is no distinction between truth and reality, there is no gate keeper of truth. Where we used to rely on scientists and journalists for our truths we now rely on celebrities who regularly regurgitate to us our own world views as much as give us factual information. 
            </p>
            <p>
            Now with social media, the advent of personalized algorithms and feeds, and the proliferation and ubiquity of data, creating a world of fake truths has become easy and in return tribalism has run rampant. The internet, much like the printing press, has given us the opportunity to create new subjective truths based on inaccurate information served by means which are perceived to be credible at the time.  In 2020, in a world where a significant portion of the United States, the richest country in the world, openly disputes scientific truths like mask-wearing during a pandemic and replaces it with a conviction that “God will protect us”—it is hard not to conclude that positivism is a failed social theory. But isn’t it also possible that we have not yet tapped the true power of the internet; that shifts in education and how we verify data will still take place like they did for education during the Enlightenment and that  fake news will again fade back into the background where it belongs? The truth is still there, we have just fallen victim to some of our beliefs that have still not been uncovered or exposed to aletheia. But if there was ever a time we needed a societal shift back towards positivism it is now.
            </p>
          </EssaySectionContent>
        </EssaySection>
        <EssaySection>
         <EssaySectionContent>
            <h2> The Truth Will Carry On </h2>
            <blockquote>
            One way to look at science is as a system that corrects for people’s natural inclinations. In a well-run laboratory, there’s no room for myside bias; the results have to be reproducible in other laboratories, by researchers who have no motive to confirm them. And this, it could be argued, is why the system has proved so successful. At any given moment, a field may be dominated by squabbles, but, in the end, the methodology prevails. Science moves forward, even as we remain stuck in place. (Kolbert 2017)
            </blockquote>
            <p>It’s easy to say that we live in a time where we have lost sight of the truth, that the wild swing towards individualism and tribalism,  pushed to the extreme by the cherry picking of data as “fact” has created a world where there is no room for a positivist outlook. But it’s absolutely not true, the digital revolution and artificial intelligence are bringing us closer to reason. But it is yet still an incomplete journey. Science and technology has opened the door but we have yet to develop the structures and institutions, like in the time prior to the Enlightenment, to properly harness them. This does not mean that science itself is wrong, no longer valid, or not our goal, only that we as humans have discoveries to make. Remember truth, alitheia, is an act with an ever moving end point. </p>
            <p>Positivism is about the eternal quest for uncovering empirical truths and using them as a basis for creating an intersubjective reality in which we can build a society around. And quantum theory is teaching us that human beings will never be able to measure absolute truth because it lies outside of the human experience. Our role as observers is a flaw in the system. Science itself, through quantum physics, is showing us gaps in our ability to currently measure truth. Post-positivism merely builds a bridge over that gap. There has been an update in our perspective on science and there for an update on our perception of reality itself. This does not make the core positivist ideas less valid, only that there are and always will be truths yet uncovered that require us to update our definitions of science, knowledge, and truth.  Comte would never deny the questions posed by quantum physics. The metaphysical of today may indeed become the science of tomorrow, but only if it rings true.  If science has taught us anything since Comte’s time, it is that the truth is still out there. And while we will never fully grasp it we must always continue to reach. </p>

         </EssaySectionContent>
        </EssaySection>
      </div>
      <div className="essay-right"></div>

    </EssayWrapper>
  )
}

export default Positivism