import React from 'react'
import P5Wrapper from "react-p5-wrapper";


const P5Component = React.memo(({width, height}) => {
    
  const sketch = (p5) => {

  let t;
 
  p5.setup = () => {
    // p5.createCanvas(width, height).parent(canvasParentRef)
    p5.createCanvas(width, height)
    
    p5.stroke(142,10, 1);
    p5.noFill();
    // p5.background(0)

    t = 0;
  }
  
  p5.draw = () => {
    console.log('YOOOO DRA')
    p5.translate(width/2, height/2);
    // p5.background(255);
    console.log('drawing')
    p5.beginShape();
    for (var i = 0; i < 200; i++) {
      let ang = p5.map(i, 0, 125, 0, p5.TWO_PI);
      let rad = 400 * p5.noise(i * 0.03, t * 0.007);
      let x = rad * p5.cos(ang);
      let y = rad * p5.sin(ang);
      p5.curveVertex(x, y);
    }
    p5.endShape(p5.CLOSE);
    t += 1;

    // p5.ellipse(p5.random(width), 100, 100)
    // p5.ellipse(300, p5.random(height), 100)
    if (p5.frameCount % 600 == 0) {
      p5.background(255);
      }
  }

 
}
  console.log('FIRING UP SKETCH')
  return <P5Wrapper key={1} sketch={sketch}  />
})




export default P5Component;