import {useState, useEffect, useContext} from 'react'
import {Link, useParams} from 'react-router-dom'
import { store } from '../../../store.js';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import FWHeader from '../FWHeader'
import {FWPostContainer} from '../styled.js'


const Post = () => {
  
  let {title} = useParams()
  console.log('TITLE', title)
  const endpoint = `https://raucms.wpengine.com/wp-json/wp/v2/posts?slug=${title}`
  const globalState = useContext(store)
  console.log('globalSTate', globalState)
  const [post, setPost] = useState({content: {rendered: "Loading"}})

  useEffect(() => {
    getPost()
  },[])

  const getPost = () => {
    fetch(endpoint).then((response) => {
      console.log('RESPONSE', response)
      // const featuredBlogs = response
      // setPosts(featuredBlogs)
     return response.json()
    })
        .then((responseData) => {
            // const allHits = responseData.records.filter(record => record.fields.number)
            const featuredBlogs = responseData
            setPost(featuredBlogs[0])
            // dispatch({type: 'SET_POSTS', payload: featuredBlogs})
      })
  }

  console.log('POST', post)
  return (
   <FWPostContainer>
      <FWHeader />
      <div className="post-container">
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{post.content.rendered}</ReactMarkdown> 
      </div> 
    </FWPostContainer>
  )
}

export default Post