import {Link} from 'react-router-dom'

const HomePanelLink = ({link, text}) => {
  return (
    <Link to={link} className="panel-overlay-link">
      <div className="content">
        {text}
      </div>
    </Link>
  )
}

export default HomePanelLink