import React from 'react';
import Sound from 'react-sound';
import playButton from '../../assets/play.png'
import pauseButton from '../../assets/pause.png'
import stopButton from '../../assets/stop.png'
import randomButton from '../../assets/ff.png'

function control(text, clickHandler) {
  const onClick = ev => {
    ev.preventDefault();
    clickHandler();
  };
  return (
    <li>
      <a href="#" onClick={onClick}>
        {text}
      </a>
    </li>
  );
}

const numberFormat = new Intl.NumberFormat([], { minimumFractionDigits: 2 });

export default class PlayerControls extends React.Component {
  render() {
    return <div className="sound-controls">{this.renderControls()}</div>;
  }

  renderControls() {
    const controls = {
      play: this.props.playStatus === Sound.status.STOPPED,
      stop: this.props.playStatus !== Sound.status.STOPPED,
      pause: this.props.playStatus === Sound.status.PLAYING,
      resume: this.props.playStatus === Sound.status.PAUSED
    };

    return (
      <div>
        {/* Volume:
        <button onClick={this.props.onVolumeDown}>-</button>
        <button onClick={this.props.onVolumeUp}>+</button> */}
        <ul className='sound-controls-ul'>
          {controls.play && control(<span className="control-button"><img src={playButton} /></span>, this.props.onPlay)}
          {controls.stop && control(<span className="control-button"><img src={stopButton} /></span>, this.props.onStop)}
          {controls.pause && control(<span className="control-button"><img src={pauseButton} /></span>, this.props.onPause)}
          {controls.resume && control(<span className="control-button"><img src={playButton} /></span>, this.props.onResume)}
        </ul>
        {/* <div>
          Playback Rate:
          <button onClick={this.props.onPlaybackRateDown}>-</button>
          {' '}
          {numberFormat.format(this.props.playbackRate)}
          {' '}
          <button onClick={this.props.onPlaybackRateUp}>+</button>
        </div>
        Loop?:
        <input type="checkbox" checked={this.props.loop} onChange={this.props.onToggleLoop} /> */}
      </div>
    );
  }
}
