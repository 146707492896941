import styled from 'styled-components'
import wood from "../assets/wood.jpeg"
import control from "../assets/speaker-cloth.png"

const maxWidth = '600px';
const emphaSize = '1.2em'

export const HomeContainer = styled.div`
 background-color: #282c34;
 position: relative;
  // width: 100%;
  // height: 100%;
  // overflow-x: hidden;
  // overflow-y: scroll;
  // perspective: 8px;
  // perspective-origin: 0%;
  // display: flex;
  .home-links-container {
    position: relative;
    display: flex;
    justify-content: center;
    
   
   
    .home-about {
      color: white;
      margin: 0 auto;
      max-width: 400px;
      padding: 6em 1em;


    }
  }
  .not-blur {
    position: relative;
    z-index: 1000;
  }
`

export const HomeSectionLinks = styled.div`
  min-height: ${props => props.height || '100vh'};
  display: flex;
  justify-content:center;
  flex-direction: column;
  align-items: center;
  transition: 500s;
  a {
    color: white;
    font-size: ${emphaSize};
    margin-bottom: .1em;
  }

  h3 {
    margin: 10px;
    text-transform:  uppercase;
    font-weight: 300;
  }

  a: hover {
    font-weight: 700;
  }

`

export const Blur =styled.div`
  position: absolute;
  z-index: 0;
  top: 44%;
  left: 51%;
  transform: translate(-50%, -50%);
  -webkit-filter: url(#svg-blur);
  filter: url(#svg-blur);

.content > img {

width: 100%;
}
#Panes {
position: relative;
z-index: 10;
}

a {
  font-size: 1.4em;
}


`

export const EssaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  .essays-nav {
    position: absolute;
    top: 200px;
    left: 25px;
  }
`
export const EssayWrapper = styled.div`

  font-size: 1.1em;
  font-family: 'Cormorant Garamond';
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  p {
    text-align: left;
    font-size: 1.2em;
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8em;
  }
  h2 {
    text-align: left;
    // font-family: 'Montserrat', sans-serif;
  }

  .left-content {
    position: fixed;
    top: 200px;
    left: 25px;
  }

  @media (max-width: 800px) {
    .left-content {
      display: none;
    }
  }
`

export const EssaySection = styled.section`
  background: ${props => props.backgroundColor || 'white'}
`

export const EssaySectionContent = styled.div`
  background: ${props => props.backgroundColor || 'white'};
  max-width: ${maxWidth};
  margin: 0 auto;
  padding: 1em;
`

export const EssayHeader = styled.section`
  background: ${props => props.headerColor || 'white'};
  padding: 2em 1em;
`
export const HeaderContainer = styled.section` 
  background: ${props => props.Color || '#282c34'};
  min-height: 100vh;
  display: grid;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  // /* position: absolute; */
  // /* top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // transform: translateZ(0px); */

  .header-text {
    font-size: 56px;
    letter-spacing: 2px;
    color: #ddd;
  }

  .header-outer {
    position: absolute; 
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    text-align: center;
  }
  .header-2 {
    font-weight: 300;
    color: rgba(255,255,255,.2); !important;
  }
  h3 {
      font-size: 24px;
      color: rgba(255,255,255,.2);
  }
`


export const HeaderSection = styled.section` 
  // background: ${props => props.Color || '#282c34'};
  width: 100%;
  height: 100%;

  .block-stat {
    font-size: 1.5em;
    display: grid;
    grid-template-columns: 210px 1fr;
  }


  .covid-block .block-stat{
    top: 10px;
    left: 10px;
    position: relative;
  }


`

export const TVContainer = styled.div`
    padding: 1em;
    height: 100vh;
    box-sizing: border-box;
    background: black;
    // background-image: url(${wood});
    background-size: cover;
  
  .muted {
    color: white;
    cursor: pointer;
    margin-bottom: .5em;

  }
  
  
  .mute-wrap {
    position: absolute;
    text-alignment: center;
    bottom: 0;

   .ui.toggle input:checked~label:before {
      background:  yellow !important;  
    }
  }

    .tv-inner-wrap {
      display: grid;
      grid-template-columns: 1fr 337px;
      height: 100%;
      gap: 20px;
    }
    .tv-controls {
      // background-image: url(${control});
      background: black;
      border-radius: .2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      justify-content: space-around;
      padding-bottom: 9em;
      a {
        // position: absolute;
        // top: 5px;
        // left: 50%;
        // transform: translate(-50%);
      }
    }
    .tv-screen {
      background: black;
      border-radius: .4em;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;

      .tv-screen-content {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        z-index: 1;
      }
      video {
        z-index: 0;
        object-fit: contain;
        height: 100%;
        width: 100%;

      }
    
      h3 {
        font-family: 'Orbitron', serif;
        color: yellow;
        font-size: 2em;
        position: absolute;
        top: 50%; left: 50%; 
        transform: translate(-50%, -50%);
        opacity: .5;
      }
    }

    .tv-dial {
      width: 200px;
      height: 240px;
      // border: 2px solid green;
      // border-radius: 100%;
      margin-top: 3em;
      display: flex;
      justify-content: space-around;;
      align-items: center;
      flex-direction: column;
    }

    .tv-feature {
      background: #222;
      padding: .5em 1em;
      font-family: 'Orbitron';
      min-width: 200px;
      min-height: 80px;
      color: yellow;
      
    }

    .tv-proj-description {
      position: absolute;
      width: 400px;
      bottom: 40px;
      background: rgba(0,0,0,.6);
      color: white;
      padding: 1em;
      right: 0;
    }

    .triangle {
      position: relative;
      background-color: darkgrey;
      text-align: left;
      transition: 1s;
      cursor: pointer;
    }

    .triangle:hover {
      background-color: yellow;
    }

    .triangle:active {
      background-color: #39FF14;
    }


    .triangle:before,
    .triangle:after {
      content: '';
      position: absolute;
      background-color: inherit;
    }
    .triangle,
    .triangle:before,
    .triangle:after {
      width:  3em;
      height: 3em;
      border-top-right-radius: 30%;
    }
    
    .tri-up {
      transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
    }
    .tri-up:before {
      transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    .tri-up:after {
      transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }

    .tri-down {
      transform: rotate(-240deg) skewX(-30deg) scale(1,.866);
    }
    .tri-down:before {
      transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    .tri-down:after {
      transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }

    @media (max-width: 1100px) {
      height: auto;
      .tv-inner-wrap {
        display: grid;
        grid-template-columns: 1fr;
      }
      .tv-controls {

        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 2em;
      }
      .tv-screen {
        min-height: 60vh;
        align-items: center;
        justify-content: center;
      }

      .tv-dial {
        width: 150px;
      }
    }

`

