import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import React, {useState, useEffect, useContext} from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { store } from '../../store.js';
import Web3 from 'web3';
import './style.css'
import {FWPostContainer} from '../FwFutures/styled.js'
import FWHeader from '../FwFutures/FWHeader'

import ScanningContainer from './ScanningHitsContainer'
// import ScanningHitsIndex from './ScanningHitIndex'

const Scannr = () => {
  const [posts, setPosts] = useState([])
  const [scans, setScans] = useState([])
  const globalState = useContext(store);
  const [isActive, setIsActive] = useState(0)
  const {dispatch} = globalState
  const [featurePost, setFeaturedPost] = useState(
  {
    content: {rendered: "Loading"},
    slug: ''
  }
  )
  
  
  useEffect(() => {
    getScans()
    // getPosts()
  },[])
  //fetch features scan hits
  // const getPosts = () => {
  //   fetch("https://api.airtable.com/v0/appCZCWlpEMmolAwH/blog?maxRecords=3&view=Grid%20view", {
  //     headers: {"Authorization": "Bearer keysWOUXtGyYz8SY2"}
  //   }).then((response) => response.json())
  //       .then((responseData) => {
  //           // const allHits = responseData.records.filter(record => record.fields.number)
  //           const featuredBlogs = responseData.records;
  //           setPosts(featuredBlogs)
  //     })

  // }

  const getPosts = () => {
    fetch('https://raucms.wpengine.com/wp-json/wp/v2/posts').then((response) => {
      console.log('RESPONSE', response)
      // const featuredBlogs = response
      // setPosts(featuredBlogs)
     return response.json()
    })
        .then((responseData) => {
            // const allHits = responseData.records.filter(record => record.fields.number)
            const featuredBlogs = responseData
            setPosts(featuredBlogs)
            dispatch({type: 'SET_POSTS', payload: featuredBlogs})
      })
  }


  const getScans = () => {
    fetch("https://api.airtable.com/v0/appf47EqIOrHTrGoP/scans?maxRecords=100&view=Grid%20view", {
      headers: {"Authorization": `Bearer ${process.env.REACT_APP_AT_KEY}`}
    }).then((response) => response.json())
        .then((responseData) => {
            const allHits = responseData.records.filter(response => response.fields.show == true);
            console.log('SCANNRR', allHits);
            setScans(allHits)
      })
 }

 const handleIndexClick = (i) => {
  // console.log(i)
  // this.setState({
  //     isActive: i
  // })
  // console.log(this.state.isActive)
}

const handleNext = () =>{
  
  const signalLength = scans.length - 1
  if (isActive > signalLength -1 ) {
      setIsActive(0)
  } else {
  setIsActive(isActive + 1)
  }
  // console.log(this.state.isActive)
}

const handleBack = () => {
  // let isActive = this.state.isActive
  const signalLength = scans.length
  // console.log(this.state.isActive)
  if (isActive <= 1) {
      setIsActive(signalLength - 1)
  } else {
    setIsActive(isActive -1)
  }
}


 console.log('CHECKING RENDER')
 return (
   <FWPostContainer>
   <FWHeader />
  <div className="scanning-hit-page post-container">
    <div className="section-content">
        {/* <div className="article-back ab-scanning-hits"><Link to='/fwfutures'>&larr; Research </Link></div> */}
        {/* <h1 className="scanning-header">Scanning Hits</h1> */}
        <div className="back-large" onClick={handleBack}>
            Prev
        </div>
        <div className="next-large" onClick={handleNext}>
            Next
        </div>
        {scans ?
        <div>
            {/* <ScanningHitsIndex isActive={this.state.isActive} allHits={this.state.allHits} onClick={this.handleIndexClick.bind(this)}/> */}
            <div className="arrow-container">
                <div className="back-arrow" onClick={handleBack}>
                    {/* <Icon name="arrow left" size="large"/> */}
                    Prev
                </div>
                <div className="next-arrow" onClick={handleNext}>
                    {/* <Icon name="arrow right" size="large"/> */}
                    Next
                </div>
            </div>
            {scans ? <ScanningContainer isActive={isActive} allHits={scans}/> : <div>loading...</div> }
        </div>: null }
    </div>
  </div>
  </FWPostContainer>
 )

}

export default Scannr