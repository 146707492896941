import {Link} from 'react-router-dom'
import TV from '../../components/TV'
import {useState, useEffect} from 'react'
import bgVid1 from '../../assets/glitch-vid-1.mp4'
// import fodVid from 'https://hifilorau.s3-us-west-2.amazonaws.com/fod-video.mp4'

const PROJECTS = [
  {name: "Future of Death",
   url: "https://futureofdeath.com",
   videoURL: 'https://hifilorau.s3-us-west-2.amazonaws.com/fod-video.mp4',
   description: "Our fear of death in the United States has given birth and nurtured a predatory death industry. New tech innovations give us the opportunity to correct that, but first we have to face death head on. This website is an exploration of healthcare, the death industry, and death technology and how they might play out in the future."
},
{name: "Durham, NC - This Page is Under Heavy Construction",
   url: "/",
   videoURL: bgVid1
},

]
const Projects = () => {
  const [projects, setProject] = useState(PROJECTS);
  const [isMuted, setIsMuted] = useState(true)
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0)
  
  
  useEffect(() => {
    let mounted = true;
    // getStream()
    //   .then(response => {
    //    console.log('STREM?', response)
    //   })
    // return () => mounted = false;
  }, [])

  const toggleMute = () => {
    setIsMuted(!isMuted)
  }

  const getStream = () => {
    return fetch('https://smartdevicemanagement.googleapis.com/v1/enterprises/1dd66344-0f6b-4f30-be71-d5e237e41ed4/devices/bMhos066xn:executeCommand', {
      method: 'post',
      body: {
        "command" : "sdm.devices.commands.CameraLiveStream.GenerateRtspStream",
        "params" : {}   
      }
    })
    .then(data => data.json())
  }

  const changeChannel = (direction) => {
    console.log('changing the channel', selectedProject, selectedProjectIndex, projects.length)

    if (direction) {
      if (selectedProjectIndex >= projects.length - 1){
      setSelectedProjectIndex(0)
      }
      else {
        console.log('+ else')
        setSelectedProjectIndex(selectedProjectIndex + 1)
      }
    } 
    else {
      if (selectedProjectIndex == 0){
        setSelectedProjectIndex(projects.length - 1)
        }
        else {
       setSelectedProjectIndex(selectedProjectIndex -1)
        }
    }
  }

  const selectedProject  = projects[selectedProjectIndex]
  console.log('selected',  projects, selectedProject.videoURL)
 
  return (
    <TV changeChannel={changeChannel} selectedProject={selectedProject} setIsMuted={toggleMute} isMuted={isMuted}>
      <div className="tv-screen-content">
        <h3 className="tv-title">{selectedProject.name}</h3>
        <div className="tv-proj-description">
          <div>{selectedProject.description}</div>
          {selectedProject.url.length > 1 && <div className="tv-descrip-link"><a target="_blank" href={selectedProject.url}>Learn More</a></div> }
        </div>
      </div>
      <video id="tv1" key={selectedProject.videoURL} playsInline autoPlay muted={isMuted} loop poster="" id="bgvid">
          <source src={selectedProject.videoURL} type="video/mp4" />
      </video>
      
    </TV>

  )
}

export default Projects