import React from 'react'
import {Link} from 'react-router-dom'
import './index.css'


class HiddenForm extends React.Component {
    constructor(props, defaultProps) {
        super(props, defaultProps)
    }

    render() {
        return (
            <div >
                <form name="holla" data-netlify="true" netlify-honeypot="bot-field" hidden>
                    <input type="email" name="email" />
                    <textarea name="message"></textarea>
                </form>
            </div>
        )
    }
}

export default HiddenForm
