import styled from 'styled-components'

export const FWFutureContainer = styled.div`
	// font-family: 'Lora';
  max-width: 1540px;
  margin: 0 auto;

  display: grid;
  grid-template: ".       header   header   ."
                  ".      left-col main-col ."
                  "footer footer   footer   footer";
  
  grid-template-columns:40px 350px 1fr 40px; 
	font-family: 'Times New Roman', serif;
  header {
    grid-area: header;
  }

	.p5-outer {
		h3 {
			margin-bottom: .5em;
		}
		min-height: 400;
		width: 100%;
		margin: 1em 0;
	}

	.post-two p:first-child {
		text-indent: 0 !important;
	}

  .left-col {
    grid-area: left-col;
    // margin-right: 20px;
    padding: 0 5px;
  } 

	.fw-read-more {
		color: #555;
		position: relative;
    top: -10px;
		// text-transform: uppercase;
	}

  .main-col {
    grid-area: main-col;
  }

  footer {
    grid-area: footer;
  }


	h1 {
	font-family: 'canterburyregular', cursive;
	font-size: 11em;
	line-height: 100%;
}

h2 {
	font-family: 'canterburyregular', cursive;
	margin-bottom: .5em;
}

p {
	font-family: "Times New Roman";
	font-size: 1.2em;
	text-indent: 1.3em;
	margin-bottom: .5em;
	text-align: justify;
}

a {
	font-family: "Times New Roman";
	margin-bottom: .5em;
	color: hotpink;
}
	
	a:hover {
	color: hotpink;
	transition: .5s;
	}

	.feature-ellipsis {
		float: left;
	}


.nl-header {
	text-align: center;
	font-size: 9vw;
	font-family: 'canterburyregular', cursive;
	margin: 0 0 .1em;
	max-width: 97%;
	padding: 0 2px;
}


.nl-header-2 {
	display: flex;
	justify-content: space-between;
	padding: 5em;

	.newsletter-social-icons a {
		color: #555;
	}

	
}

	
	.top-line {
		margin-top: -10px;
	
	}

  .nl-scan {
    white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
    max-width: 335px;
  }
	
	
	.newspaper-subheader {
		margin-bottom: 0;
	}

.header-small {
	font-size: 1em;
	padding: 0 .5em;
}

.header-2-container {
	max-width: 85%;
	margin: 0 auto 0;
}


.flex-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	// padding: 1em 5em;
	text-align: justify;
	/*padding: 0 30px;*/
}
	
	.nl-hl-2 {
		font-family:'Lora', serif;
		font-size: 1.9em;
		text-align: justify;
		letter-spacing: 2px;
		line-height: 1;
	
	}
	
	
	.nl-hl-3, .nl-hl-4 {
		font-family: 'Lora', serif;
		font-size: 1.6em;
		text-transform: uppercase;
		text-align: left;
		letter-spacing: .03em;
	}
	
	.nl-hl-4 {
		margin-bottom: 0;
		font-size: 1.9em;
			line-height: .9;
		letter-spacing: .05em;
	}

  .nl-list {
    padding-left: 3px;
		margin-top: 2px;
  }
	
	
	.nl-list-container {
		h3{
			font-size: 1.4em;
			font-family: 'Lora';
			margin: .5em 0 0;
			line-height: .9;
		}


		p:first-child {
			margin-top: 5px;
		}
		

			
			.nl-list li {
				list-style: none;
				margin-bottom: 10px;
			
			}

			.fw-tags {
				background: grey;
				padding: .1em .25em;
				color: white;
				display: inline-block;
				font-size: 14px;
				border-radius: .2em;
			  margin-right: .24em;
				font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
			}
			
		
			.nl-list li a {
				color: #333; 
				font-size: 1.2em;
				font-family: 'Georgia';
				display: inline-block;
				margin-bottom: .1em;
				font-weight: 400;
			}

			.nl-list li a:hover {
				color: hotpink;
			}
		
		  .nl-list li .open-link{
				color: $link-color-news;
				transition: .5s;
	    }
		  .nl-list li .open-link:hover{
				color: $link-news-hover;	
			}
		  
	}
	
	
	#play, #stop {
		display: inline-block;
		font-family: HFLR, wingdings;
		color: white;
		font-size: 1.4em;
		cursor: pointer;
		
	}
	
	.audio-controls {
		padding: .7em .5em .5em;
		border-radius: .1em;
		background-color: #444;
	}
	
	.song, .artist{
		display: inline-block;
		text-align: center;
		font-size: 1.2em;
		margin: .7em 0;	
		font-weight: 700;
	}
	
	
	.audio-container {
		p {
			text-indent: 1em;
		}
	
	}

	.play-text, .pause-text{
		display: inline-block;
		font-size: 1.4em;
		vertical-align: top;
		color: white;
	}
	


.column-one {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	margin-bottom: 0;
}


.column-one h1 {
	font-size: 2em;
	margin: 0;
	font-family: 'Lora', serif;
	text-align: left;
	text-transform: uppercase;
}



.column-two {
	flex: 2;
	padding: 0 20px;
	min-width: 342px;
	/*max-width: 80%;*/
}

.column-two h1 {
	font-size: 3em;
	margin: 0 0 10px;
	font-family: 'Lora', serif;
	font-style: italic;
	letter-spacing: -2.5px;
	word-spacing: 0px;
	font-weight: 400;
	text-align: left;
}

.column-two h2 {
	font-size: 2em;
	font-family: 'Lora', serif;
	padding: .5em 0;
	line-height: .9;
}

.newspaper-pic {
	width: 100%;
	height: auto;
}
	
	.newspaper-caption {
	  font-weight: 700;
		font-size: .8em;
		font-style:italic;
		margin-bottom: .5em;
		line-height: 1;
	}


form {
	padding: 1em;
	/*background-color: rgb(239, 237, 237);*/
	text-align: center;
}

form h1 {
	text-align: center;
}


input {
	width: 250px;
	border: none;
	border-bottom: 1px solid black;
	font-family: "Times New Roman";;
	font-size: 1em;
	color: rgb(49, 109, 158);
	background-color: rgb(244, 242, 242);
	text-transform: uppercase;
	margin: 10px;
}

input:focus {
	outline: none;
}


label {
	font-family: "Times New Roman"
}

button {
	margin-top: 1em;
	width: 200px;
	border: none;
	background-color: $link-color-news;
	text-transform: uppercase;
	padding: 10px;
	font-size: 1em;
	transition: all .5s;

	outline: none;
	background-color: hotpink;
	color: white;
  cursor: pointer;
		transition: .5s;
}

button:hover {
	background-color: hotpink;
	color: white;
}
	
	button:active {
	/*background-color: red;*/
		box-shadow: none;
		padding: 10px 8px;
		margin-top: 1.1em;
	}


.newsletter-footer {
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: .25em;
	// margin:0 auto 1em;
	color: #555;
	background-color: #444;
	// max-width: 85%;
	height: 25px;
	min-height: 25px !important;




	p {
		display:inline-block;
		color: white;
		 margin: 0;
	   padding: 0;
	   font-size: 1.2rem;
	}

	a {color: white;
	   margin: 0;
	   padding: 0;
	   font-family: 'Times';
	   font-size: 1.2rem;
		}
}

.signup-column2 {
	margin-bottom: 1.5em;
	
	form {
		border: 1px solid;
	}
} 

.fw-tags {
	display
}

@media only screen and (max-width: 1100px) {
	max-width: 940px;
  margin: 0 auto;

  display: grid;
  grid-template: "header"
									"main-col"
                  "left-col"
                  "footer";
  
  grid-template-columns: 1fr; 
	font-family: 'Times New Roman', serif;

	.nl-header {
		max-width: 10vw;
	}
}
`

export const FWPostContainer = styled.div`
h1 {
	font-family: 'canterburyregular', cursive;
	font-size: 11em;
	line-height: 100%;
}

.fw-mast-link {
	color: #333;
	transition: .5s;
}

.fw-mast-link:hover {
	color: pink;
}

.nl-header {
	text-align: center;
	font-size: 8vw;
	font-family: 'canterburyregular', cursive;
	margin: 0;
	max-width: 97%;
	padding: 0 2px;
}


.nl-header-2 {
	display: flex;
	justify-content: space-between;
	list-style: none;
	.newsletter-social-icons a {
		color: #555;
	}
	line-height: 1;
	margin: 0;
	background: #333;
	color: white;
	padding: .5em;
}

	
	.top-line {
		margin-top: -10px;
	
	}

  .nl-scan {
    white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
    max-width: 335px;
  }
	
	
	.newspaper-subheader {
		margin-bottom: 0;
	}

.header-small {
	font-size: 1em;
	padding: 0 .5em;
}

.header-2-container {
	// max-width: 85%;
	margin: .5em auto 1em;
}


.flex-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	// padding: 1em 5em;
	text-align: justify;
	/*padding: 0 30px;*/
}
	
	.nl-hl-2 {
		font-family:'Lora', serif;
		font-size: 1.9em;
		text-align: justify;
		letter-spacing: 2px;
		line-height: 1;
	
	}
	
	
	.nl-hl-3, .nl-hl-4 {
		font-family: 'Lora', serif;
		font-size: 1.6em;
		text-transform: uppercase;
		text-align: left;
		letter-spacing: .03em;
	}
	
	.nl-hl-4 {
		margin-bottom: 0;
		font-size: 1.9em;
			line-height: .9;
		letter-spacing: .05em;
	}

  .nl-list {
    padding-left: 3px;
  }

	.post-container {
		max-width: 800px;
		margin: 0 auto;
		padding: 1em;
	}

	p {
		font-size: 1.2em;
		color: #444;
		// font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: 300;
	}



	@media only screen and (max-width: 900px) {
		.nl-header {
			font-size: 10vw;
		}
	}
	
	

`