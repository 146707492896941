import React from 'react'
import {Link} from 'react-router-dom'
import './index.css'

class TOC extends React.Component {
  constructor(props) {
       super()
   }


  render() {
    return (
      <div className="home-menu">
        <ul className="home-menu-ul">
          {/* <li onClick={this.props.onListenClick}>Listen</li> */}
          {/* <li><a target="_blank" href="http://www.future-of-education.hifilorau.com/">Other</a></li> */}
          {/* <li onClick={this.props.onFollowClick}>Follow</li> */}
          {/* <li onClick={this.props.signupClick}>Talk</li> */}
          {/* <li onClick="" >Contact</li> */}
        </ul>
      </div>
    )
  }
}

export default TOC
