import React from 'react'
import {Link} from 'react-router-dom'
import './index.css'

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

class Signup extends React.Component {
    constructor(props, defaultProps) {
        super(props, defaultProps)

        this.state = {
          email: '',
          message: '',
          // showThanks: false
        }

       this.handleEmail = this.handleEmail.bind(this);
       this.handleMessage= this.handleMessage.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this);
       this.handleBotChange = this.handleBotChange.bind(this);
      }

 handleEmail(e) {
     this.setState({email: e.target.value});
 }
 handleMessage(e) {
     this.setState({message: e.target.value});
 }
 handleBotChange(e) {
 }

 handleSubmit = e => {
   fetch("/", {
     method: "POST",
     headers: { "Content-Type": "application/x-www-form-urlencoded" },
     body: encode({ "form-name": "holla", ...this.state })
   })
     .then(() => {
        this.props.afterSubmit(this.state.message)
     })
     .catch(error => alert(error));

   e.preventDefault();
 }



   render() {
     return (
       <div>
         <form className="contact-form"
                 name="holla"
                 method="post"
                 action="/"
                 data-netlify="true"
                 data-netlify-honeypot="bot-field"
                 onSubmit={this.handleSubmit}
                 >
                 <input type="hidden" name="bot-field"/>
                 <div className="contact-form-input-container">
                     <label>
                       <input type="email" id="email" name="email" placeholder="Email Address" value={this.state.email || ''} onChange={this.handleEmail} ></input>
                     </label>
                     <label className="contact-message">
                       <textarea type="textarea" name="message" id="message" placeholder="Send me a message, (include your email if you don't want to be anonymous)." value={this.state.message || ''} onChange={this.handleMessage}></textarea>
                     </label>
                 </div>
                 <button type="submit" className='contact-submit'>GO</button>
                 <p>Or just <a target="_blank" href="mailto:tomrau@gmail.com">email me</a></p>
             </form>

       </div>
     )
   }
}



export default Signup
