import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import {EssaysContainer} from '../../styles/styled-components.js'


const Essays = () => {
  return (
  <EssaysContainer>
    <div className="essays-nav">
      <Link to="/">Home</Link>
    </div>
    <h1 className="header-text">
                FW<span className="header-2">FUTURES</span>
              </h1>
    {/* <p>These are a few essays I've written.</p> */}
    {/* <div className="essay-list"> */}
      <Link to="/essays/post-positivism">Post Positivism</Link>
    {/* </div> */}
  
  </EssaysContainer>
  )
}

export default Essays