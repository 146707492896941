import React, {createContext, useReducer} from 'react';

const initialState = {
  posts: [],
  categories: []
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    console.log('STATE from store', state)
    let newState = state;
    switch(action.type) {
      case 'SET_POSTS':
        newState = {...state, posts: action.payload };
        console.log('NEW STATE', newState)
        return newState;
      case 'SET_CATEGORIES':
        newState = {...state, categories: action.payload };
        return newState;
      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }