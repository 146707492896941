import React from 'react'
import {Link} from 'react-router-dom'
import "./style.css"
import { Card, Icon, Divider, Container, Label, Header } from 'semantic-ui-react'

class ScanningHit extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
        // isActive: 1
    }
  }

    componentDidMount() {
        this.handleSteep()
    }

    handleSteep() {
        let steep = this.props.hit.fields.STEEP || [];
        console.log(this.props.hit.fields.STEEP);
        steep = steep.join(' ')
        this.setState({steep})
    }


    render () {
        console.log("HIT", this.state.isActive, this.props.hit)
        if (this.props.isActive == this.props.index ) {
        return (
            <Container>
            <div className="scanning-hit-container" key={this.props.hit.fields.id}>
              <div className="scanning-hit-header">
              {/* <div className="next-scan" onClick={this.handleNext.bind(this)}>Next</div> */}
                  <div className="hit-meta-container">
                      <div className="hit-meta-top-left">
                         <div className="sh-title-wrapper"><div className="sh-title">{this.props.hit.fields.Name}</div></div>
                         <div><a target="_blank" href={this.props.hit.fields.url}>{this.props.hit.fields.url}</a></div>
                          <div className="sh-author">{this.props.hit.fields.author}</div>
                      </div>
                      <div className="sh-date">{this.props.hit.fields.date}</div>
                  </div>
              </div>
          
               <Divider />
        


              <div className="sh-content-containers">
                  <div>
                      <p><label>Description: </label>{
                          this.props.hit.fields.description}
                      </p>
                  </div>
                  <Divider hidden />
                  <div>
                      <p><label>How this impacts the future: </label>
                        {this.props.hit.fields.futureChange}
                      </p>
                  </div>
                  <Divider hidden />
                   <div>
                      <p><label>Stakeholder implications: </label>
                        {this.props.hit.fields.implications}
                      </p>
                  </div>
              </div>
              <Divider />
              <div className="sh-source"><label>Source: </label>{this.props.hit.fields.source}</div>
          
              <div className="sh-steep"><label>STEEP: </label>{this.state.steep}</div>
              <div className="sh-keywords"><label>Keywords: </label>{this.props.hit.fields.keywords}</div>
              <div className="sh-type"><label>Signal Type: </label>{this.props.hit.fields.signalType}</div>
              <div className="sh-client"><label>Disrupted Party: </label>{this.props.hit.fields.client}</div>
              <div className="sh-horizon"><label>Horizon: </label>{this.props.hit.fields.horizon}</div>
              <Divider />
            
            
              <div className="rating-container">
                <label>Impact Scores</label>
                <Card.Group className="ratings-group">
                    <Card>
                      <Card.Content>
                        <Card.Header>Impact</Card.Header>
                            {this.props.hit.fields.impact}
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Plausibility</Card.Header>
                            {this.props.hit.fields.plausibility}
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Novelty</Card.Header>
                            {this.props.hit.fields.novelty}
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Credibility</Card.Header>
                            {this.props.hit.fields.credibility}
                      </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content>
                      <Card.Header>Timeliness</Card.Header>
                          {this.props.hit.fields.timeliness}
                    </Card.Content>
                </Card>
                <Card>
                  <Card.Content>
                    <Card.Header>Viability</Card.Header>
                        {this.props.hit.fields.timeliness}
                  </Card.Content>
                </Card>
                </Card.Group>
              </div>            


            <div className="sh-submission-date">Submitted on {this.props.hit.fields.submissionDate} by Tom Rau</div>
         </div>
        </Container>
      )} else return (
          null
      )
    }
}

ScanningHit.defaultProps = {
  hit: []
};

export default ScanningHit