import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from "./containers/Home/home.js"
import Essays from "./containers/Essays/essays.js"
import Positivism from './containers/Essays/positivism.js'
import Projects from './containers/Projects/projects.js'
import Gallery from './containers/Gallery/'
import FWFutures from './containers/FwFutures'
import Post from './containers/FwFutures/Post'
import Scannr from './containers/Scannr'
import { StateProvider } from './store.js';


function App() {
  return (
    <div className="App">
      <StateProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/essays" component={Essays}></Route>
            <Route exact path="/projects" component={Projects}></Route>
            <Route path="/essays/post-positivism" component={Positivism} />
            <Route path="/fwfutures/:title" component={Post}></Route>
            <Route exact path="/fwfutures" component={FWFutures} />
            <Route exact path="/scannr" component={Scannr} />
            <Route path="/work" component={Projects} />
            <Route path="/gallery" component={Gallery} />
          </Switch>
        </Router>
      </StateProvider>
    </div>
  );
}

export default App;
