import React from 'react'
import {Link} from 'react-router-dom'
import './index.css'
import Typist from 'react-typist';



class Messages extends React.Component {
    constructor(props, defaultProps) {
        super(props, defaultProps)

        this.state = {
            events:[]
        }
    }

    componentDidMount() {
        console.log(this.props);
        this.setState({
            events: this.props.events
        }, () => {console.log(this.state.events)})
    }

    renderEvents() {
        console.log(this.state.events)
        return (
        this.state.events.map((event) => {
            return (
        <li key={event.id}>
            <Typist
                cursor={{
                    show: false,
                    hideWhenDone:true
                }}
            >
                <div>{event.fields.message}</div>
                <Typist.Delay key={event.extra} ms={1000} /> {event.fields.extra}
            </Typist>
        </li>
            )
        })
      )
    }

    render() {
        let currentMessage = this.props.messages[0].fields
        console.log(currentMessage);
        let events = this.state.events

        return (
            <div className="message-box">
                <ul className="event-ul">
                    { !currentMessage.message ? this.renderEvents() : null}
                </ul>
                <Typist
                    key={currentMessage.message}
                    cursor={{
                        show: false,
                        hideWhenDone:true
                    }}
                    >
                <div>{currentMessage.message}</div>
                <Typist.Delay key={currentMessage.extra} ms={1000} /> {currentMessage.extra}
                </Typist>
            </div>
        )
    }
}

export default Messages
